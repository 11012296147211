import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import ProjectSeries from "../components/ProjectSeries"

const FrcPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>FIRST Robotics Competition</h1>
            {ProjectSeries({
                name: "FRC",
                nextname: "Spark Drive",
                nextlink: "/spark",
                prevname: "Spark Drive",
                prevlink: "/spark"
            })}
            <p>FIRST Robotics Competition (FRC) is a high school robotics competition in which students design and build a ~120-pound robot. Following a 45-day build period, the robots compete in a unique game each year developed by FIRST, the organization which manages FRC and similar programs. For three of my high school years, I was a member of FIRST Robotics Competition team 2848 All Sparks (Now 2848 Rangers). While on the team, I held multiple roles including team president during my senior year, 2017 (Steamworks). The team provided me many unique experiences including an introduction to machining and SolidWorks, the latter of which helped me secure my first job with KidKraft. I worked on many unique problems, my favorite of which, the Spark Drive, you can read about <a href="/spark">here</a>. While on the team, I didn't really do any programming work, rather, I was heavily involved on the mechanical side, from design to fabrication to assembly and repairs, which is funny in hindsight that I ended up as a computer science major. It was really an excellent experience.</p>
            <p>It's not exaggeration to say that FRC changed my life: It got me my first job at <a href="/kidkraft">KidKraft</a>. It got me involved with the <a href="/sumobots">Aggie Robotics</a> group at Texas A&amp;M. I met many great people while volunteering at events. And now, as part of my job at <a href="/swirl">SwIRL</a>, we are exploring the logistics of starting FRC teams and hosting a competition.</p>
            <p>Some links possibly of interest:</p>
            <ul>
                <li><a href="https://github.com/AllSparks2848">All Sparks on Github</a></li>
                <li><a href="https://www.thebluealliance.com/team/2848">All Sparks (now Rangers) on The Blue Alliance</a></li>
                <li><a href="https://www.youtube.com/watch?v=mIDmrpqMHlY">2017 Robot Reveal on YouTube</a></li>
            </ul>
            {FooterBar()}
        </div>
    )
}

export default FrcPage